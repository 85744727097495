import {backend} from "./backend";
import helpers from "../assets/js/helpers";

const cadastrar = async (formulario) => {
    let uri = "api/usuarios";
    let dados = traduzirFormularioUsuario(formulario);
    let response = await backend.post(uri, dados, helpers.montarHeaderToken());
    return response;
};

const editar = async (id, formulario) => {
    let uri = `api/usuarios/${id}`;
    let dados = traduzirFormularioUsuario(formulario);
    let response = await backend.put(uri, dados, helpers.montarHeaderToken());
    return response;
};

const excluir = async (id) => {
    let uri = `api/usuarios/${id}`;
    let response = await backend.delete(uri, helpers.montarHeaderToken());
    return response;
};

const alterarSenha = async (id, formulario) => {
    let uri = `api/alterar-senha/${id}`;
    let dados = traduzirFormularioSenha(formulario);
    let response = await backend.post(uri, dados, helpers.montarHeaderToken());
    return response;
};

export default {
    cadastrar,
    editar,
    excluir,
    alterarSenha
};

const traduzirFormularioUsuario = (formulario) => {
    return {
        nome: formulario.nome,
        usuario: formulario.usuario,
        senha: formulario.senha,
        tipo_documento: formulario.tipoDocumento,
        documento: formulario.documento,
        email: formulario.email,
        ddd: formulario.ddd,
        telefone: formulario.telefone,
        administrador: formulario.admin,
        habilitado: formulario.habilitado,
        empresa_id: formulario.empresaId,
        representante_id: formulario.representante_id ?? null,
    }
};

const traduzirFormularioSenha = (formulario) => {
    return {
        senha_atual: formulario.senhaAtual,
        nova_senha: formulario.novaSenha,
        confirmacao_nova_senha: formulario.confirmacaoNovaSenha
    }
};