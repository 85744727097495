<template>
  <menu-principal>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <titulo-pagina>{{ tituloPagina }}</titulo-pagina>
        </v-col>

        <v-col cols="12" v-if="!permissaoPagina">
          <bloco-erro>Acesso Negado</bloco-erro>
        </v-col>

        <v-col cols="12" v-if="permissaoPagina && !usuarioEncontrado">
          <bloco-erro>Usuário não Encontrado</bloco-erro>
        </v-col>

        <v-col cols="12" v-if="permissaoPagina && usuarioEncontrado">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      dense
                      v-model.trim="formulario.nome"
                      :label="labels.nomeRazao"
                      :error-messages="erros.nome"
                      prepend-inner-icon="mdi-face-man"
                      outlined
                      counter
                      maxlength="100"
                      :disabled="bloqueia_form_rep"
                      :append-icon="(!!erros.nome && iconeErro) || ''"
                  />
                </v-col>

                <v-col cols="12" class="pt-0">
                  <v-text-field
                      dense
                      v-model.trim="formulario.email"
                      label="Email"
                      :error-messages="erros.email"
                      prepend-inner-icon="mdi-email"
                      outlined
                      counter
                      maxlength="100"
                      autocomplete="usuarios"
                      :disabled="bloqueia_form_rep"
                      :append-icon="(!!erros.email && iconeErro) || ''"
                  />
                </v-col>

                <v-col cols="12" md="6" class="pt-0">
                  <v-text-field
                      dense
                      v-model.trim="formulario.usuario"
                      label="Login do Usuário"
                      :error-messages="erros.usuario"
                      prepend-inner-icon="mdi-account"
                      outlined
                      counter
                      maxlength="20"
                      :append-icon="(!!erros.usuario && iconeErro) || ''"
                  />
                </v-col>

                <v-col cols="12" md="6" class="pt-0">
                  <v-text-field
                      dense
                      v-if="tipoSubmit === 'cadastrar'"
                      v-model.trim="formulario.senha"
                      label="Senha"
                      :error-messages="erros.senha"
                      prepend-inner-icon="mdi-lock"
                      outlined
                      type="password"
                      autocomplete="false"
                      :hint="hintSenha"
                      :append-icon="(!!erros.senha && iconeErro) || ''"
                  />
                </v-col>

                <v-col cols="12" md="6" class="pt-0">
                  <v-select
                      dense
                      v-model="formulario.tipoDocumento"
                      label="Tipo do Documento"
                      :error-messages="erros.tipoDocumento"
                      :items="itensSelect.tipoDocumento"
                      prepend-inner-icon="mdi-card-account-details"
                      outlined
                      :disabled="bloqueia_form_rep"
                  />
                </v-col>

                <v-col cols="12" md="6" class="pt-0">
                  <v-text-field
                      dense
                      v-model.trim="formulario.documento"
                      :label="labels.documento"
                      :error-messages="erros.documento"
                      prepend-inner-icon="mdi-card-account-details"
                      outlined
                      v-mask="['###.###.###-##', '##.###.###/####-##']"
                      :append-icon="(!!erros.documento && iconeErro) || ''"
                      :disabled="bloqueia_form_rep"
                  />
                </v-col>

                <v-col cols="12" md="6" class="pt-0">
                  <v-select
                      dense
                      v-model.trim="formulario.admin"
                      label="Permissão de Administrador"
                      :error-messages="erros.admin"
                      :items="itensSelect.administrador"
                      item-text="label"
                      item-value="valor"
                      :disabled="bloqueia_form_rep"
                      prepend-inner-icon="mdi-shield-account"
                      outlined
                  />
                </v-col>

                <v-col cols="12" md="6" class="pt-0">
                  <v-select
                      dense
                      v-model="formulario.habilitado"
                      label="Habilitação do Usuário"
                      :error-messages="erros.habilitado"
                      :items="itensSelect.habilitacao"
                      item-text="label"
                      item-value="valor"
                      prepend-inner-icon="mdi-list-status"
                      outlined
                  />
                </v-col>

                <v-col cols="12" md="6" class="pt-0">
                  <v-text-field
                      dense
                      v-model.trim="formulario.ddd"
                      label="DDD"
                      :error-messages="erros.ddd"
                      prepend-inner-icon="mdi-phone"
                      outlined
                      v-mask="'##'"
                      :append-icon="(!!erros.ddd && iconeErro) || ''"
                      :disabled="bloqueia_form_rep"
                  />
                </v-col>

                <v-col cols="12" md="6" class="pt-0">
                  <v-text-field
                      dense
                      v-model.trim="formulario.telefone"
                      label="Telefone"
                      :error-messages="erros.telefone"
                      prepend-inner-icon="mdi-phone"
                      outlined
                      v-mask="['###-####', '####-####', '#####-####']"
                      :disabled="bloqueia_form_rep"
                      :append-icon="(!!erros.telefone && iconeErro) || ''"
                  />
                </v-col>

                <v-col cols="12" class="pt-0" v-if="permissaoMaster && mostraEmpresa">
                  <v-select
                      dense
                      v-model="formulario.empresaId"
                      label="Seleciona a Empresa"
                      :error-messages="erros.empresaId"
                      :items="itensSelect.empresas"
                      item-text="label"
                      item-value="valor"
                      prepend-inner-icon="mdi-face-man"
                      outlined
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col align="right" class="ml-auto" cols="4" md="8">
                  <v-btn
                      :block="blockBtns"
                      dark
                      class="mr-4 ,mb-4"
                      depressed
                      color="orange"
                      @click="confirmarSaida()"
                  >Ir para Listagem
                    <v-icon right>mdi-table-account</v-icon>
                  </v-btn>

                  <v-btn
                      dark
                      depressed
                      @click="submit()"
                      color="var(--cor-primaria-100)"
                  >{{ tipoSubmit === "cadastrar" ? "Registrar" : "Editar" }}
                    <v-icon right>mdi-send-check</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </menu-principal>
</template>

<script>
import MenuPrincipal from "../../components/MenuPrincipal";
import TituloPagina from "../../components/TituloPagina";
import BlocoErro from "../../components/BlocoErro";
import mixinGeral from "../../mixin/geral";
import usuariosServices from "../../services/usuarios";
import representanteServices from "@/services/representantes";
import helpers from "@/assets/js/helpers";
import {mask} from "vue-the-mask";

export default {
  name: "Formulario",
  mixins: [mixinGeral],
  directives: {mask},
  components: {
    MenuPrincipal,
    TituloPagina,
    BlocoErro,
    representanteServices
  },
  data() {
    return {
      hintSenha: '* A senha deve conter entre 6 e 15 caracteres, sendo pelo menos um caractere maiúsculo, um minúsculo, um especial e um numérico.',
      usuarioEncontrado: true,
      idUsuario: null,
      mostraEmpresa: true,
      bloqueia_form_rep: false,
      formulario: {
        nome: null,
        usuario: null,
        senha: null,
        tipoDocumento: null,
        documento: null,
        email: null,
        ddd: null,
        telefone: null,
        admin: null,
        habilitado: null,
        empresaId: null,
        representante_id: null,
      },
      erros: {
        nome: null,
        usuario: null,
        tipoDocumento: null,
        documento: null,
        email: null,
        ddd: null,
        telefone: null,
        admin: null,
        habilitado: null,
        empresaId: null,
      },
    };
  },
  computed: {
    iconeErro() {
      return "mdi-alert-octagram";
    },
    permissaoPagina() {
      return this.permissaoMaster || this.permissaoAdmin;
    },
    tipoSubmit() {
      return this.$route.meta.submit;
    },
    tituloPagina() {
      if (this.tipoSubmit === "cadastrar") {
        return "Cadastrar Usuário";
      }
      return "Editar Usuário";
    },
    labels() {
      const tipoDocumento = this.formulario.tipoDocumento;
      const labelDocumento = ["CPF", "CNPJ"].includes(tipoDocumento) ? tipoDocumento : "Documento";

      return {
        nomeRazao: tipoDocumento === "CNPJ" ? "Razão Social" : "Nome",
        documento: `${labelDocumento} do Usuário`,
      };
    },
    itensSelect() {
      return {
        tipoDocumento: ["CPF", "CNPJ"],
        habilitacao: [
          {
            valor: false,
            label: "Desabilitado",
          },
          {
            valor: true,
            label: "Habilitado",
          },
        ],
        administrador: [
          {valor: false, label: "Usuário Comum"},
          {valor: true, label: "Administrador"},
        ],
        empresas: this.montarItensEmpresas(),
      };
    },
    mascaraTelefone() {
      const telefone = this.formulario.telefone;

      if (!telefone) return;

      const tamanho = telefone.replace("-", "").length;

      if (tamanho <= 7) return "###-####";
      if (tamanho == 8) return "####-####";

      return "#####-####";
    },
    mascaraDocumento() {
      const tipo = this.formulario.tipoDocumento;
      return tipo === "CPF" ? "###.###.###-##" : "##.###.###/####-##";
    },
    blockBtns() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    habilitacaoSubmit() {
      return !this.loading && !this.notificacao.status;
    },
  },
  methods: {
    buscarUsuario() {
      const id = this.$route.params.id;
      const usuario = this.usuarios.find((u) => Number(u.id) === Number(id));

      if (!usuario) {
        this.usuarioEncontrado = false;
      } else {
        this.idUsuario = usuario.id;

        this.formulario.nome = usuario.nome;
        this.formulario.usuario = usuario.usuario;
        this.formulario.senha = usuario.senha;
        this.formulario.tipoDocumento = usuario.tipoDocumento;
        this.formulario.documento = usuario.documento;
        this.formulario.email = usuario.email;
        this.formulario.ddd = usuario.ddd;
        this.formulario.telefone = usuario.telefone;
        this.formulario.admin = usuario.admin;
        this.formulario.habilitado = usuario.habilitado;
        this.formulario.empresaId = usuario.empresaId;

      }
    },
    montarItensEmpresas() {
      if (!this.permissaoMaster) return [];
      const retorno = [];
      this.empresas.forEach((empresa) => {
        retorno.push({
          valor: Number(empresa.id),
          label: empresa.razaoSocial,
        });
      });
      return retorno;
    },
    confirmarSaida() {
      this.$confirm("Deseja ser direcionado para lista de Usuarios ?").then((res) => {
        if (res) {
          this.navegar("usuariosIndex");
        }
      })
    },
    submit() {
      // if (!this.habilitacaoSubmit) return;
      if (this.tipoSubmit === "cadastrar") return this.cadastrar();
      return this.atualizar();
    },
    async cadastrar() {
      this.erros = {};
      this.ativarLoading();
      try {
        await usuariosServices.cadastrar(this.formulario);
        this.notificacaoSucesso("Usuário Cadastrado");
        this.navegar("usuariosIndex");
      } catch (e) {
        this.interpretarErro(e);
        if (this.obterCodigoRequisicao(e) === 422) {
          this.apresentarErros(e.response.data.erros);
        }
      } finally {
        this.desativarLoading();
      }
    },
    async atualizar() {
      this.erros = {};
      this.ativarLoading();
      try {
        await usuariosServices.editar(this.idUsuario, this.formulario);
        this.notificacaoSucesso("Usuário Editado");
        this.navegar("usuariosIndex");
      } catch (e) {
        this.interpretarErro(e);
        if (this.obterCodigoRequisicao(e) === 422) {
          this.apresentarErros(e.response.data.erros);
        }
      } finally {
        this.desativarLoading();
      }
    },
    apresentarErros(erros) {
      if (!helpers.empty(erros.nome)) this.erros.nome = erros.nome[0];
      if (!helpers.empty(erros.usuario)) this.erros.usuario = erros.usuario[0];
      if (!helpers.empty(erros.senha)) this.erros.senha = this.hintSenha;
      if (!helpers.empty(erros.tipo_documento)) this.erros.tipoDocumento = erros.tipo_documento[0];
      if (!helpers.empty(erros.documento)) this.erros.documento = erros.documento[0];
      if (!helpers.empty(erros.email)) this.erros.email = erros.email[0];
      if (!helpers.empty(erros.ddd)) this.erros.ddd = erros.ddd[0];
      if (!helpers.empty(erros.telefone)) this.erros.telefone = erros.telefone[0];
      if (!helpers.empty(erros.administrador)) this.erros.admin = erros.administrador[0];
      if (!helpers.empty(erros.habilitado)) this.erros.habilitado = erros.habilitado[0];
      if (!helpers.empty(erros.empresa_id)) this.erros.empresaId = erros.empresa_id[0];
    },

    async buscarRepresentantentante(id) {
      await representanteServices.buscar(id).then(resp => {
        this.mostraEmpresa = false;
        this.formulario.representante_id = resp.data.id
        this.formulario.nome = resp.data.nome_razao;
        this.formulario.tipoDocumento = resp.data.cpf_cnpj.length > 14 ? 'CNPJ' : 'CPF';
        this.formulario.documento = resp.data.cpf_cnpj;
        this.formulario.email = resp.data.email;
        this.formulario.ddd = resp.data.ddd;
        this.formulario.telefone = resp.data.telefone;
        this.formulario.habilitado = true;
        this.formulario.empresaId = 1;
        this.formulario.admin = false;

        this.bloqueia_form_rep = true;
      });
    }
  },
  watch: {},
  created() {
    if (this.$route.name == 'usuariosCadastrarRepresentante') {
      this.buscarRepresentantentante(this.$route.params.id)
    }
    if (this.tipoSubmit === "cadastrar") {
      this.formulario.empresaId = Number(this.usuarioLogado.empresaId);
    } else {
      this.buscarUsuario();
    }
  },
};
</script>